<template>
    <div>
        <div class="resetPasswordTitle">Reset Password</div>
        <div class="resetPasswordContentDiv">
            <div class="resetPasswordContentCell">
                <a-input class="loginInput" style="" placeholder="Password" v-model="resetpassword" :size="'large'"/>
                <a-button class="loginBtn" type="primary" :size="'large'" :style="{ fontSize: '20px'}"  @click="handleResetClick">
                    Reset
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
export default {
    
    data() {
        return {
            disabled:false,
            resetpassword:''
        }
    },
    methods: {
        handleResetClick: async function(){
            const _info = {
                token: this.resetToken,
                password: this.resetpassword
            }
            const res = await api.resetPassword(_info)
            if (res!= null && res._id != undefined){
                this.$message.success('Reset success. Please login.');
                // window.open("/","_self");// "_blank"
            }
            else{
                this.$message.error('Reset Failed!');
            }
        }
    },
    
    mounted(){
        this.resetToken = this.$route.query.token;
    }
}
</script>